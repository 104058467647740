html, body, #root {
    width: 100%;
    height: 100%;
}

body {
    font-size: 16px;
    min-width: 1200px;
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}
a:visited, a {
    color: black;
}

@media only screen and (max-width: 768px) {
    body {
        min-width: unset;
    }
    html, body {
        position: relative;
    }
}



.app {
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.app > div{
    padding-left: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.content {
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.content .right-col {
    z-index:100;
    width: calc(100% - 200px);
}
.content .right-col, .content .left-col{
    float: right;
    height: 100%;
}
.header {
    padding-top: 9px;
    padding-bottom:9px;
    border-bottom:1px solid rgba(0,0,0,0);
    margin-bottom:8px;
    overflow:hidden;
    cursor: pointer;
    position: absolute;
    z-index: 10000;
}

.menu {
    position: relative;
    z-index: 11;
}


a.menu:hover{
    opacity: 0;
}

body.information .menu-information.corner-menu {
    display: none;
}

body.show-modal #root {
   display: none;
}

.loading-container, .loading-error-container {
    /*display:flex;*/
    /*flex: auto;*/
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.header-ln {
    width: 200%;
    font-size: 20px;
    font-weight: 500;
    line-height:24px;
    overflow:hidden;
}

.header-seg {
    display: inline-block;
}

.header-hide {
   opacity: 0;
}

.header:hover{
    border-bottom:1px solid black;
}

.header:hover .header-seg{
    opacity:1 !important;
}

/*.header:hover .header-seg:hover{*/
    /*opacity:0.5 !important;*/
/*}*/

.header-seg-STUDIO, .header-seg-EO, .header-seg-立入禁止 {
    padding-right: 6px;
}
.side-menu {
    z-index: 10000;
    padding-top: 52px;
    padding-right: 10px;
    width: 190px;
    position: absolute;
}
.side-menu .menu-item{
    display: block;
    height: 24px;
}
.side-menu .menu-item.menu-shop > a {
    line-height: 24px;
}
.side-menu .menu-item > a{
    border-top: solid 1px rgba(0,0,0,0);
    border-bottom: solid 1px rgba(0,0,0,0);
    line-height: 22px;
    display: inline-block;
    /*vertical-align: middle;*/
}
.side-menu.menu-shop .menu-item > a{
    line-height: 23px;
}
body.work .menu-works.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.works .menu-works.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.about .menu-about.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.contact .menu-contact.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}

.menu-shop {
    margin-top: 22px;
}

.content-container {
    padding-top: 52px;
    position: absolute;
}
.content-container {
    padding-top: 50px;
}
.content-container.content-description {
    position: relative;
    padding-top: 146px;
}

.content-container .content-text-left,
.content-container .content-text-right,
.content-container .content-text-navs{
    vertical-align: top;
    display: inline-block;
}
.content-container .content-text-navs {
    padding-left: 48px;
}

.content-container .content-text-right {
    /*max-width: 686px !important;*/
    /*min-width: 457px !important;*/
}
.content-text-left {
    border-top: solid 1px rgba(0,0,0,0);
    border-bottom: solid 1px rgba(0,0,0,0);
    line-height: 22px;
}

.content-text-lines > div{
    min-height:24px;
}

.mcontent-navs {
    display: none;
    width: 92px;
    position: absolute;
    right: 10px;
    top: 50px;
    z-index: 10000;
}
.mcontent-navs a{
    display: block;
    text-align: right;
}
.mcontent-navs .nav-home {
    padding-bottom: 24px;
}


@media only screen and (max-width: 768px) {
    a:hover {
        color: #555555;
    }
    body, html, #root, .app, .app > div, .content {
        overflow: visible;
    }
    .content .right-col, .content .left-col {
        /*height: unset;*/
    }
    .work-content {
        /*height: unset !important;*/
        /*overflow: unset;*/
    }
    .mcontent-navs {
        display: block;
    }
    .side-menu {
        display: none;
    }
    .header-hide {
        opacity: 1;
    }
    .header{
        border-bottom:1px solid black;
        position: absolute;
    }
    .header-ln {
        width: 2000%;
    }
    .content {
        position: absolute;
        width: calc(100% - 10px);
    }
    .content .right-col {
        width: 100% !important;
        overflow: visible;
    }
    .content .content-container:first-child {
        padding-top:60px;
    }
    .content-container {
        padding-top: 24px;
        width: 100%;
        position: relative;
    }
    .content-container .content-text-left {
        width: 100% !important;
    }
    .content-container .content-text-right {
        width: 100% !important;
        max-width: unset !important;
        min-width: unset !important;
    }
    body.work .menu-works.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.works .menu-works.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.about .menu-about.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.contact .menu-contact.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
}

.home-content {
    display: block;
    height: 100%;
    position: relative;
}
.home-content .content-container {
    z-index: 100;
}

.home-cover {
    position: absolute;
    right:0;
    top:0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;
}
.home-cover > div{
    padding-top: 230px;
}
.home-cover > div > svg{
    float: right
}
.mhome-cover {
    display: none;
}
.home-icons {
    cursor: pointer;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    right: 24px;
    width: 226px;
    height: 210px;
    overflow: hidden;
}
.home-icons .swiper-container {
    width: 100%;
    height: 100%;
}
.home-icons .swiper-container .swiper-slide{
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

@media only screen and (max-width: 768px) {
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .home-content {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -10px;
        width: calc(100% + 10px);
        position: relative;
    }
    body.home .side-menu {
        display: block;
    }
    body.home .content {
        overflow:visible;
    }
    .home-content .content-container {
        display: none;
    }
    .home-content .home-cover {
        display: none;
    }
    .content .left-col {
        height: auto;
    }
    .mhome-cover {
        padding-top: 222px;
        display: block;
        overflow-x: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;
    }
    .home-icons {
        width: 180px;
        height: 150px;
    }
    .home-icons .swiper-container .swiper-slide {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: none;
    }
    .mhome-cover > div {
        padding-left: 10px;
        transition-property: all;
        transition-property: transform;
        transition-duration: 3s;
        -o-transition-timing-function: ease-in-out;
    }
}

.works-container {
    width: 100%;
    height: 100%;
    overflow-x:hidden;
    overflow-y:auto;
}
.works-content {
    padding-top: 50px;
    margin:0 -8px;
}

.work-grid {
    width: 25%;
    position: relative;
    padding: 8px 8px 64px 8px;
    box-sizing: border-box;
    display: inline-block;
}

.work-grid > a{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    cursor: pointer;
}

body.works .right-col {
    overflow: hidden;
    overflow-y: auto;
}


@media only screen and (max-width: 768px) {
    body.works .side-menu {
        display: block;
    }
    .work-grid:nth-child(even){
        padding: 30px 5px 30px 10px;
    }
    .work-grid:nth-child(even){
        padding: 30px 10px 30px 5px;
    }
    .work-grid {
        width: 50%;
        position: relative;
        box-sizing: border-box;
        display: inline-block;
    }
    .works-content {
        padding-top: 50px;
        margin: 0 0 0 -10px;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }
    .works-container {
        overflow: hidden;
    }
    body.works .content .right-col {
        /*overflow-y: scroll; !* has to be scroll, not auto *!*/
    }
}

.work-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}
.work-content-scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.work-description {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 28px;
}
.work-credits {
    height: 96px;
}
.work-images img {
    display: block;
    width: 100%;
    height: auto;
}
.work-images video {
    width: 100%;
    height: auto;
}

.scrolling .work-description{
    opacity: 0;
}
.scrolling .content-header .content-text-right{
    opacity: 0;
}
.content-text-navs > a{
    cursor: pointer;
    display: block;
}
.content-text-navs > a:hover{
    opacity: 0.5;
}
.work-content .mcontent-container {
    display: none;
}
.work-content .mcontent-title {
    display: none;
}

@media only screen and (max-width: 768px) {
    .work-content-scroll {
        display: none;
    }
    /*body.work .content-text-left {*/
        /*padding-bottom: 24px;*/
    /*}*/
    /*.content-text-left > a.nav-home {*/
        /*display: inline-block;*/
        /*width: 92px;*/
        /*padding-right: 10px;*/
        /*text-align: right;*/
    /*}*/
    /*.content-text-left > div {*/
        /*display: inline-block;*/
        /*width: calc(100% - 102px) !important;*/
    /*}*/
    /*body.work .content-text-right{*/
        /*width:   calc(100% - 102px) !important;*/
    /*}*/
    /*body.work .content-text-navs{*/
        /*padding-right: 10px;*/
        /*width: 92px !important;*/
        /*padding-left:0;*/
        /*text-align: right;*/
    /*}*/
    /*body.work .content-container.content-description {*/
        /*padding-top: 0px;*/
    /*}*/
    /*body.scrolling .content-text-lines {*/
        /*display:  block !important;*/
    /*}*/
    /*.scrolling .work-description{*/
        /*opacity: 1;*/
    /*}*/
    /*.scrolling .content-header .content-text-right{*/
        /*opacity: 1;*/
    /*}*/
    /*body.work .content-container.content-header {*/
        /*position: absolute;*/
    /*}*/
    .work-content .mcontent-title {
        display: block;
        width:calc(100% - 102px);
        position: absolute;
        padding-top: 50px;
        z-index: 10000;
    }
    body.work .side-menu{
        display: none;
    }
    .work-content .mcontent-container {
        display:block;
        width: calc(100% + 10px);
        height: 100%;
        margin: 0 0 0 -10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .work-content .mcontent-container .work-credits,
    .work-content .mcontent-container .work-description
    {
        width:calc(100% - 102px);
    }
    .work-content .mcontent-container .work-images {
        display: block;
        width:100%;
    }
    .work-content {
        overflow: visible;
    }

    .work-content > .work-images {
        display: none;
    }
    .work-content .content-container {
        display: none;
    }
    .work-content .mcontent-container .work-title{
       padding-top: 50px;
    }
    .work-content .mcontent-container .work-credits{
        padding-left:10px;
        padding-top: 98px;
        padding-bottom: 24px;
    }
    .work-content .mcontent-container .work-description{
        padding-left:10px;
    }
    body.scrolling .content-text-lines {
        display: block !important;
    }
    /*body.scrolling .work-credits {*/
        /*opacity: 0;*/
    /*}*/
    body.scrolling .work-description {
        opacity: 1;
    }
    .work-content .work-title {
        position: absolute;
        top: 0px;
    }
    .mcontent-navs {
        display: block;
        width: 92px;
        position: absolute;
        right: 10px;
        top: 50px;
    }
    .mcontent-navs a{
        display: block;
        text-align: right;
    }
    .mcontent-navs .nav-home {
        padding-bottom: 24px;
    }

    body.work .content {
        /*overflow-y: scroll;*/
        /*-webkit-overflow-scrolling: touch;*/
    }
    body.work .work-credits {
        height: auto
    }

}
.about-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.about-content .content-container {
    position: relative;;
}

@media only screen and (max-width: 768px) {

    .about-content .content-found-in {
        padding-top: 50px !important;
        z-index: 1000;
    }
    .about-content .content-found-in > .content-text-left{
        position: absolute;
        width: calc(100% - 102px);
    }
    .about-content .content-found-in > .content-text-right{
        padding-top: 48px;
    }
    body.about .content-container .content-text-left{
        padding-bottom: 24px;
    }
    .about-content::after {
        content:'';
        display: block;
        padding-bottom: 24px;
    }
    .about-content{
        box-sizing: border-box;
        padding-right: 10px;
    }
}
body.scrolling .content-text-lines {
    display:none;
}

.contact-content {
    display: block;
    height: 100%;
}
.contact-content .content-text-lines {
    height: 72px;
    overflow: hidden;
}

.contact-cover {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.contact-cover > img{
    padding-top: 152px;
    width: 100%;
    display: block;
    height: auto;
}
.contact-cover > div > svg{
    float: right
}
.mcontact-cover {
    display: none;
}
.mcontact-cover > img{
    padding-top: 24px;
    width: 100%;
    display: block;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .contact-content .content-container {
        padding-top: 50px;
    }

    .contact-content .content-container .content-text-left {
        position: absolute;
    }

    .contact-content .content-container .content-text-right {
        padding-top: 48px;
    }

    .contact-cover{
        display: none;
    }
    .mcontact-cover {
        display: block;
    }
}

