html, body, #root {
    width: 100%;
    height: 100%;
}

body {
    font-size: 16px;
    min-width: 1200px;
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}
a:visited, a {
    color: black;
}

@media only screen and (max-width: 768px) {
    body {
        min-width: unset;
    }
    html, body {
        position: relative;
    }
}

