
.app {
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.app > div{
    padding-left: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.content {
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.content .right-col {
    z-index:100;
    width: calc(100% - 200px);
}
.content .right-col, .content .left-col{
    float: right;
    height: 100%;
}
.header {
    padding-top: 9px;
    padding-bottom:9px;
    border-bottom:1px solid rgba(0,0,0,0);
    margin-bottom:8px;
    overflow:hidden;
    cursor: pointer;
    position: absolute;
    z-index: 10000;
}

.menu {
    position: relative;
    z-index: 11;
}


a.menu:hover{
    opacity: 0;
}

body.information .menu-information.corner-menu {
    display: none;
}

body.show-modal #root {
   display: none;
}

.loading-container, .loading-error-container {
    /*display:flex;*/
    /*flex: auto;*/
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
}

.header-ln {
    width: 200%;
    font-size: 20px;
    font-weight: 500;
    line-height:24px;
    overflow:hidden;
}

.header-seg {
    display: inline-block;
}

.header-hide {
   opacity: 0;
}

.header:hover{
    border-bottom:1px solid black;
}

.header:hover .header-seg{
    opacity:1 !important;
}

/*.header:hover .header-seg:hover{*/
    /*opacity:0.5 !important;*/
/*}*/

.header-seg-STUDIO, .header-seg-EO, .header-seg-立入禁止 {
    padding-right: 6px;
}
.side-menu {
    z-index: 10000;
    padding-top: 52px;
    padding-right: 10px;
    width: 190px;
    position: absolute;
}
.side-menu .menu-item{
    display: block;
    height: 24px;
}
.side-menu .menu-item.menu-shop > a {
    line-height: 24px;
}
.side-menu .menu-item > a{
    border-top: solid 1px rgba(0,0,0,0);
    border-bottom: solid 1px rgba(0,0,0,0);
    line-height: 22px;
    display: inline-block;
    /*vertical-align: middle;*/
}
.side-menu.menu-shop .menu-item > a{
    line-height: 23px;
}
body.work .menu-works.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.works .menu-works.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.about .menu-about.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}
body.contact .menu-contact.menu-item > a{
    border-top: solid 1px rgb(0,0,0);
    border-bottom: solid 1px rgb(0,0,0);
}

.menu-shop {
    margin-top: 22px;
}

.content-container {
    padding-top: 52px;
    position: absolute;
}
.content-container {
    padding-top: 50px;
}
.content-container.content-description {
    position: relative;
    padding-top: 146px;
}

.content-container .content-text-left,
.content-container .content-text-right,
.content-container .content-text-navs{
    vertical-align: top;
    display: inline-block;
}
.content-container .content-text-navs {
    padding-left: 48px;
}

.content-container .content-text-right {
    /*max-width: 686px !important;*/
    /*min-width: 457px !important;*/
}
.content-text-left {
    border-top: solid 1px rgba(0,0,0,0);
    border-bottom: solid 1px rgba(0,0,0,0);
    line-height: 22px;
}

.content-text-lines > div{
    min-height:24px;
}

.mcontent-navs {
    display: none;
    width: 92px;
    position: absolute;
    right: 10px;
    top: 50px;
    z-index: 10000;
}
.mcontent-navs a{
    display: block;
    text-align: right;
}
.mcontent-navs .nav-home {
    padding-bottom: 24px;
}


@media only screen and (max-width: 768px) {
    a:hover {
        color: #555555;
    }
    body, html, #root, .app, .app > div, .content {
        overflow: visible;
    }
    .content .right-col, .content .left-col {
        /*height: unset;*/
    }
    .work-content {
        /*height: unset !important;*/
        /*overflow: unset;*/
    }
    .mcontent-navs {
        display: block;
    }
    .side-menu {
        display: none;
    }
    .header-hide {
        opacity: 1;
    }
    .header{
        border-bottom:1px solid black;
        position: absolute;
    }
    .header-ln {
        width: 2000%;
    }
    .content {
        position: absolute;
        width: calc(100% - 10px);
    }
    .content .right-col {
        width: 100% !important;
        overflow: visible;
    }
    .content .content-container:first-child {
        padding-top:60px;
    }
    .content-container {
        padding-top: 24px;
        width: 100%;
        position: relative;
    }
    .content-container .content-text-left {
        width: 100% !important;
    }
    .content-container .content-text-right {
        width: 100% !important;
        max-width: unset !important;
        min-width: unset !important;
    }
    body.work .menu-works.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.works .menu-works.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.about .menu-about.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    body.contact .menu-contact.menu-item > a{
        border-top: solid 1px rgba(0,0,0, 0);
        border-bottom: solid 1px rgba(0,0,0,0);
    }
}
