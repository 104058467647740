.work-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}
.work-content-scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.work-description {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 28px;
}
.work-credits {
    height: 96px;
}
.work-images img {
    display: block;
    width: 100%;
    height: auto;
}
.work-images video {
    width: 100%;
    height: auto;
}

.scrolling .work-description{
    opacity: 0;
}
.scrolling .content-header .content-text-right{
    opacity: 0;
}
.content-text-navs > a{
    cursor: pointer;
    display: block;
}
.content-text-navs > a:hover{
    opacity: 0.5;
}
.work-content .mcontent-container {
    display: none;
}
.work-content .mcontent-title {
    display: none;
}

@media only screen and (max-width: 768px) {
    .work-content-scroll {
        display: none;
    }
    /*body.work .content-text-left {*/
        /*padding-bottom: 24px;*/
    /*}*/
    /*.content-text-left > a.nav-home {*/
        /*display: inline-block;*/
        /*width: 92px;*/
        /*padding-right: 10px;*/
        /*text-align: right;*/
    /*}*/
    /*.content-text-left > div {*/
        /*display: inline-block;*/
        /*width: calc(100% - 102px) !important;*/
    /*}*/
    /*body.work .content-text-right{*/
        /*width:   calc(100% - 102px) !important;*/
    /*}*/
    /*body.work .content-text-navs{*/
        /*padding-right: 10px;*/
        /*width: 92px !important;*/
        /*padding-left:0;*/
        /*text-align: right;*/
    /*}*/
    /*body.work .content-container.content-description {*/
        /*padding-top: 0px;*/
    /*}*/
    /*body.scrolling .content-text-lines {*/
        /*display:  block !important;*/
    /*}*/
    /*.scrolling .work-description{*/
        /*opacity: 1;*/
    /*}*/
    /*.scrolling .content-header .content-text-right{*/
        /*opacity: 1;*/
    /*}*/
    /*body.work .content-container.content-header {*/
        /*position: absolute;*/
    /*}*/
    .work-content .mcontent-title {
        display: block;
        width:calc(100% - 102px);
        position: absolute;
        padding-top: 50px;
        z-index: 10000;
    }
    body.work .side-menu{
        display: none;
    }
    .work-content .mcontent-container {
        display:block;
        width: calc(100% + 10px);
        height: 100%;
        margin: 0 0 0 -10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .work-content .mcontent-container .work-credits,
    .work-content .mcontent-container .work-description
    {
        width:calc(100% - 102px);
    }
    .work-content .mcontent-container .work-images {
        display: block;
        width:100%;
    }
    .work-content {
        overflow: visible;
    }

    .work-content > .work-images {
        display: none;
    }
    .work-content .content-container {
        display: none;
    }
    .work-content .mcontent-container .work-title{
       padding-top: 50px;
    }
    .work-content .mcontent-container .work-credits{
        padding-left:10px;
        padding-top: 98px;
        padding-bottom: 24px;
    }
    .work-content .mcontent-container .work-description{
        padding-left:10px;
    }
    body.scrolling .content-text-lines {
        display: block !important;
    }
    /*body.scrolling .work-credits {*/
        /*opacity: 0;*/
    /*}*/
    body.scrolling .work-description {
        opacity: 1;
    }
    .work-content .work-title {
        position: absolute;
        top: 0px;
    }
    .mcontent-navs {
        display: block;
        width: 92px;
        position: absolute;
        right: 10px;
        top: 50px;
    }
    .mcontent-navs a{
        display: block;
        text-align: right;
    }
    .mcontent-navs .nav-home {
        padding-bottom: 24px;
    }

    body.work .content {
        /*overflow-y: scroll;*/
        /*-webkit-overflow-scrolling: touch;*/
    }
    body.work .work-credits {
        height: auto
    }

}