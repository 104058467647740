.about-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.about-content .content-container {
    position: relative;;
}

@media only screen and (max-width: 768px) {

    .about-content .content-found-in {
        padding-top: 50px !important;
        z-index: 1000;
    }
    .about-content .content-found-in > .content-text-left{
        position: absolute;
        width: calc(100% - 102px);
    }
    .about-content .content-found-in > .content-text-right{
        padding-top: 48px;
    }
    body.about .content-container .content-text-left{
        padding-bottom: 24px;
    }
    .about-content::after {
        content:'';
        display: block;
        padding-bottom: 24px;
    }
    .about-content{
        box-sizing: border-box;
        padding-right: 10px;
    }
}