.works-container {
    width: 100%;
    height: 100%;
    overflow-x:hidden;
    overflow-y:auto;
}
.works-content {
    padding-top: 50px;
    margin:0 -8px;
}

.work-grid {
    width: 25%;
    position: relative;
    padding: 8px 8px 64px 8px;
    box-sizing: border-box;
    display: inline-block;
}

.work-grid > a{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    cursor: pointer;
}

body.works .right-col {
    overflow: hidden;
    overflow-y: auto;
}


@media only screen and (max-width: 768px) {
    body.works .side-menu {
        display: block;
    }
    .work-grid:nth-child(even){
        padding: 30px 5px 30px 10px;
    }
    .work-grid:nth-child(even){
        padding: 30px 10px 30px 5px;
    }
    .work-grid {
        width: 50%;
        position: relative;
        box-sizing: border-box;
        display: inline-block;
    }
    .works-content {
        padding-top: 50px;
        margin: 0 0 0 -10px;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }
    .works-container {
        overflow: hidden;
    }
    body.works .content .right-col {
        /*overflow-y: scroll; !* has to be scroll, not auto *!*/
    }
}
