@import '~swiper/dist/css/swiper.css';

.home-content {
    display: block;
    height: 100%;
    position: relative;
}
.home-content .content-container {
    z-index: 100;
}

.home-cover {
    position: absolute;
    right:0;
    top:0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;
}
.home-cover > div{
    padding-top: 230px;
}
.home-cover > div > svg{
    float: right
}
.mhome-cover {
    display: none;
}
.home-icons {
    cursor: pointer;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    right: 24px;
    width: 226px;
    height: 210px;
    overflow: hidden;
}
.home-icons .swiper-container {
    width: 100%;
    height: 100%;
}
.home-icons .swiper-container .swiper-slide{
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

@media only screen and (max-width: 768px) {
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .home-content {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: -10px;
        width: calc(100% + 10px);
        position: relative;
    }
    body.home .side-menu {
        display: block;
    }
    body.home .content {
        overflow:visible;
    }
    .home-content .content-container {
        display: none;
    }
    .home-content .home-cover {
        display: none;
    }
    .content .left-col {
        height: auto;
    }
    .mhome-cover {
        padding-top: 222px;
        display: block;
        overflow-x: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;
    }
    .home-icons {
        width: 180px;
        height: 150px;
    }
    .home-icons .swiper-container .swiper-slide {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: none;
    }
    .mhome-cover > div {
        padding-left: 10px;
        transition-property: all;
        transition-property: transform;
        transition-duration: 3s;
        -o-transition-timing-function: ease-in-out;
    }
}
