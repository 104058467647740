body.scrolling .content-text-lines {
    display:none;
}

.contact-content {
    display: block;
    height: 100%;
}
.contact-content .content-text-lines {
    height: 72px;
    overflow: hidden;
}

.contact-cover {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.contact-cover > img{
    padding-top: 152px;
    width: 100%;
    display: block;
    height: auto;
}
.contact-cover > div > svg{
    float: right
}
.mcontact-cover {
    display: none;
}
.mcontact-cover > img{
    padding-top: 24px;
    width: 100%;
    display: block;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .contact-content .content-container {
        padding-top: 50px;
    }

    .contact-content .content-container .content-text-left {
        position: absolute;
    }

    .contact-content .content-container .content-text-right {
        padding-top: 48px;
    }

    .contact-cover{
        display: none;
    }
    .mcontact-cover {
        display: block;
    }
}
